<template>
    <v-dialog
            v-model="show"
            max-width="40%">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    color="primary">
                <v-icon left>
                    mdi-plus
                </v-icon>
                Add New Role
            </v-btn>
        </template>
        <v-card >
            <div style="position: absolute; align-self: end; right: 0">
                <v-btn
                        icon
                        @click="show = !show"
                        class="ma-1" >
                    <v-icon color="red" >mdi-window-close</v-icon>
                </v-btn>
            </div>
            <v-card-title
                    class="justify-center">
                Add New User
            </v-card-title>
            <v-divider></v-divider>
            <v-progress-linear
                    v-show="loading"
                    indeterminate
                    color="primary"
                    background-opacity="0">
            </v-progress-linear>
            <v-card-text>
                <v-form
                        ref="addNewRoleForm"
                        lazy-validation
                        class="px-6">
                    <v-text-field
                            v-model="role.name"
                            label="Role Name"
                            :rules="rules.nameRules"
                            required>
                    </v-text-field>
                    <v-textarea
                            outlined
                            label="Description"
                            @keyup.enter="addNewRole"
                            v-model="role.description"
                    ></v-textarea>
                    <v-btn
                            @keyup.enter="addNewRole"
                            @click="addNewRole"
                            color="primary"
                            dark
                            block
                            large>
                        Add Role
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script>

  export default {
    name: 'addNewRole',
    data(){
      return{
        show: null,
        loading: false,
        role:{
          name: '',
          description: '',
        },
        rules:{
          nameRules: [
            v => !!v || 'Role name is required',
          ],
          descriptionRules: [
            v => !!v || 'Description is required',
          ],
        }
      }
    },
    methods:{
      addNewRole(){
        if(this.$refs.addNewRoleForm.validate()){
          this.loading = true;
          this.$store.dispatch('addNewRole', this.role)
            .then(response => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch('showSnackBar', {
                text: response,
                color: 'success'
              })
            }, error => {
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error'
              })
            })
        }
      }
    }
  }
</script>