<template>
    <v-container
            fluid>
        <v-card
                elevation="0"
                class="px-3">
            <v-card-title
                    class="text-h4">
                Role Management
                <v-spacer></v-spacer>
                <addNewRole></addNewRole>
            </v-card-title>          
        </v-card>

        <v-card
                elevation="0"
                class="px-3 mt-3" >
            <v-card-title>
                <span v-if="userRoles.length <= 1">{{userRoles.length}} Role</span>
                <span v-else>{{userRoles.length}} Roles</span>
                <v-spacer></v-spacer>
                <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search User Role by Name"
                        single-line
                        hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="userRoles"
                    :search="search">
                <template v-slot:item.name="{item}">
                    {{item.data.name}}
                </template>
                <template v-slot:item.userCount="{item}">
                    {{item.data.userCount}}
                </template>
                <template v-slot:item.dateCreated="{item}">
                        {{item.data.dateCreated | formatDate}}
                </template>
                <template v-slot:item.lastUpdated="{item}">
                    {{item.data.lastUpdated | formatDate}}
                </template>
                <template v-slot:item.description="{item}">
                    {{item.data.description | trimLength}}
                </template>
                <template v-slot:item.actions="{ item }">
                    <editAndDeleteRole
                            :roleIndex="item">
                    </editAndDeleteRole>
                </template>
            </v-data-table>
        </v-card>
    </v-container>

</template>

<script>
  import {mapGetters} from 'vuex';
  import moment from 'moment'
  import addNewRole from '@/components/dashboard/admin/addNewRole.vue'
  import editAndDeleteRole from '@/components/dashboard/admin/editAndDeleteRole.vue'

  export default {
    name: 'roleManagement',
    components:{
      addNewRole,
      editAndDeleteRole
    },
    data () {
      return {
        loading: true,
        search: '',
        headers: [
          { text: 'Role Name', align: 'start', value: 'name'},
          { text: 'Description', value: 'description' },
          { text: 'Users', value: 'userCount' },
          { text: 'Last Updated', value: 'lastUpdated' },
          { text: 'Date Created', value: 'dateCreated' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
      }
    },
    computed:{
      ...mapGetters(['userRoles']),
    },
    filters:{
      formatDate(val){
        if(!val) {return '-'}
        let date = val.toDate();
        return moment(date).fromNow();
      },
      trimLength(val){
        if(val.length < 200) {return val}
        return `${val.substring(0,50)}...`
      }
    },
    mounted(){
      this.$store.dispatch('getUserRoles')
        .then(() => {
          this.loading = false;
        }, error => {
          this.$store.dispatch('showSnackBar', {
            text: error,
            color: 'error'
          })
        })
    },
  }
</script>