<template>
    <v-dialog
            v-model="show"
            max-width="40%">
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="mr-2"
                    @click="editRole">
                mdi-pencil
            </v-icon>
            <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    @click="deleteRole">
                mdi-delete
            </v-icon>
        </template>
        <v-card >
            <div style="position: absolute; align-self: end; right: 0">
                <v-btn
                        icon
                        @click="show = !show"
                        class="ma-1" >
                    <v-icon color="red" >mdi-window-close</v-icon>
                </v-btn>
            </div>
            <v-card-title
                    class="justify-center">
                {{formTitle}}
            </v-card-title>
            <v-divider></v-divider>
            <v-progress-linear
                    v-show="loading"
                    indeterminate
                    color="primary"
                    background-opacity="0">
            </v-progress-linear>
            <v-card-text v-if="actionType==='edit'">
                <v-form
                        ref="editRoleForm"
                        lazy-validation
                        class="px-6">
                    <v-text-field
                            v-model="role.name"
                            label="Role Name"
                            :rules="rules.nameRules"
                            required>
                    </v-text-field>
                    <v-textarea
                            outlined
                            label="Description"
                            @keyup.enter="addNewRole"
                            v-model="role.description"
                    ></v-textarea>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                text
                                @click="show = !show">
                            Cancel
                        </v-btn>
                        <v-btn
                                color="primary"
                                text
                                @click="updateRole">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
            <v-card-actions v-if="actionType==='delete'">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="show = ! show">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="confirmDeleteRole">OK</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
  export default {
    name: 'editAndDeleteRole',
    props:{
      roleIndex: Object,
    },
    data(){
      return{
        show: null,
        actionType: '',
        formTitle: '',
        loading: false,
        role:{
          name: '',
          description: '',
          id: null,
        },
        rules:{
          nameRules: [
            v => !!v || 'Role name is required',
          ],
          descriptionRules: [
            v => !!v || 'Description is required',
          ],
        }
      }
    },
    methods:{
      editRole(){
        this.formTitle = "Edit Role";
        this.actionType = 'edit';
        this.role = Object.assign({}, this.roleIndex.data);
        this.role.id = this.roleIndex.id;
      },
      deleteRole(){
        this.formTitle = 'Are you sure you want to delete this role?'
        this.actionType = 'delete';
      },
      updateRole(){
        if(this.$refs.editRoleForm.validate()){
          this.loading = true;
          this.$store.dispatch('updateRole', this.role)
            .then(response => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch('showSnackBar', {
                text: response,
                color: 'success'
              })
            }, error => {
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error'
              })
            })
        }
      },
      confirmDeleteRole(){
        this.loading = false;
        this.$store.dispatch('deleteRole', this.roleIndex.id)
          .then(response => {
            this.loading = false;
            this.show = false;
            this.$store.dispatch('showSnackBar', {
              text: response,
              color: 'success'
            })
          }, error => {
            this.$store.dispatch('showSnackBar', {
              text: error,
              color: 'error'
            })
          })
      }


    }
  }
</script>
